import Link from 'next/link';
import PropTypes from 'prop-types';
import Styles from './HomePortfolio.module.scss';
import Card from 'components/Card';
import Loading from 'components/Loading';

export default function HomePortfolio({ data }) {
    const error = null;

    return (
        <section className={`section has-background-white ${Styles.portfolio}`}>
            <div className="container has-text-centered">
                <h3 className="title is-unselectable has-text-primary mb-6">Latest work</h3>
                <hr />
                <p className="subtitle has-text-gray">Check out my latest work.</p>

                <div className="container has-text-left mt-6">
                    <div className="columns">
                        <div className="column is-10 is-offset-1">
                            {error && (
                                <h2 className="subtitle has-text-danger">
                                    Oops. Failed to load...
                                </h2>
                            )}

                            {!data && <Loading />}

                            <div className={`columns is-multiline is-centered`}>
                                {!error &&
                                    data &&
                                    data.slice(0, 4).map((item, index) => (
                                        <div
                                            key={item.id}
                                            className={`column ${
                                                index === 0
                                                    ? 'is-full-mobile is-full-tablet is-full-desktop is-full-widescreen is-full-fullhd'
                                                    : 'is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd'
                                            } `}
                                        >
                                            <Card data={item} cover={false} />
                                        </div>
                                    ))}
                            </div>

                            <p className="has-text-centered mt-6">
                                <Link href="/portfolio">
                                    <a className="button is-info is-medium">See more work</a>
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

HomePortfolio.propTypes = {
    data: PropTypes.array,
};
