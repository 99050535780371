import PropTypes from 'prop-types';
import Styles from './Loading.module.scss';

export default function Loading({ children }) {
    return (
        <div className="is-flex is-justify-content-center is-align-items-center">
            <div className={Styles.loading}>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

Loading.propTypes = {
    children: PropTypes.object,
};
